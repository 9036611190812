<template>

  <div class="d-flex flex-column justify-center text-center w-100 card-actions-price">
    <div v-if="product.price_deviation > 0">
      <p><strong>{{ convertToGermanCurrency(product.price_deviation) }}</strong>/Monat für
        <strong>{{ product.price_deviation_months }}</strong> Monate,
         ab dem <strong>{{ product.price_deviation_months + 1 }}.</strong> Monat
        <strong>{{ convertToGermanCurrency(product.price_per_month) }}</strong>/Monat.</p>
      <p v-if="product.price_unique > 0"> + einmalig {{ convertToGermanCurrency(product.price_unique)}} {{product?.description_price_unique }}  </p>
    </div>
    <div v-else>
      <p><strong>{{ convertToGermanCurrency(product.price_per_month) }}</strong> pro Monat</p>
      <p v-if="product.price_unique > 0"> + einmalig {{ convertToGermanCurrency(product.price_unique) }} {{product?.description_price_unique }} </p>
    </div>

  </div>

</template>


<script>


import {convertToGermanCurrency} from "@/services/Helper.service";

export default {
  methods: {convertToGermanCurrency},

  props: ['product'],

}


</script>