import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import {useMaskStore} from "@/stores/MaskStore";
import {API_URL} from "@/services/Helper.service";

export const usePackageModelProductStore = defineStore('PackageModelProductsStore', {

    state: () => {

        const packageModelData: any[] = [];
        const summed_price_unique = 0;
        const summed_price_per_month = 0;
        const summed_deviation_per_month = 0;

        return {

            packageModelData,
            summed_price_unique,
            summed_price_per_month,
            summed_deviation_per_month

        };
    },

    actions: {

        /**
         * Used to update the locally saved Product Checked state
         * @param product
         */
        updatePackageModelProducts(product: any) {

            const packageModelProductStore = usePackageModelProductStore();
            const maskStore = useMaskStore();

            const maskObj = maskStore.getMaskObjByPageId(2);

            if (product.mask_id === maskObj.id) {

                this.packageModelData.forEach(item => {
                    if (item.mask_id === maskObj.id && item.id !== product.id) {
                        item.checked = false;
                    }
                });
            }

            const targetProductIndex = this.packageModelData.find(obj => obj.id === product.id);

            if (targetProductIndex !== -1) {
                packageModelProductStore.$patch((state) => {
                    state.packageModelData[targetProductIndex] = product;
                })
                console.log("product updated! new product", product)
            } else {
                throw Error("Product to update not found");
            }

        },

        async getPackageModelProductsByPriceModelId(priceModelId: any) {


            console.log("fetching PackageModelProducts");

            const data = await axiosInstance.get(API_URL + '/api/packagemodel/pricemodel/' + priceModelId, {
                headers: {
                    'Authorization': `token`
                }
            }).then((response) => {
                if (response.status !== 200) {
                    throw new Error(response.statusText);
                }
                this.packageModelData = response.data;
            });
        },
    },

    getters: {},
    persist: {
        storage: sessionStorage
    }
});