<template>

  <v-dialog
      v-model="isDialogOpen"
      :scrim="true"
      overlay-color="black"
      overlay-opacity="1"
      width="auto"
      persistent
  >

    <v-card>

      <v-card-title>
        <div class="d-flex justify-center"><h2>Kauf abschließen</h2></div>
      </v-card-title>

      <v-card-text>

        <div v-if="finishFormRequestPending === true">

          In Bearbeitung
          <v-progress-linear
              indeterminate
              color="#2E6F82"
          ></v-progress-linear>

        </div>

        <div v-if="finishFormError === true">

          Es ist ein Fehler aufgetreten

        </div>

      </v-card-text>
      <v-card-actions class="pa-4">

        <button v-if="finishFormError === true && finishFormRequestPending === false" @click="this.$emit('close-dialog')"
                class="button-standard-continue d-flex align-center justify-center">
          <span class="poppins medium">Schließen</span>
        </button>

      </v-card-actions>
    </v-card>
  </v-dialog>


</template>

<script>

export default {

  props: ['dialogOpen', 'finishFormError', 'finishFormRequestPending'],

  computed: {
    isDialogOpen(){
      return this.dialogOpen;
    },
  }
}

</script>
