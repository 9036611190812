import { createApp } from 'vue'
import App from './App.vue'
import vuetify from "@/plugins/vuetify";
import pinia from "@/plugins/pinia";
import Vue3Signature from "vue3-signature"
import {loadFonts} from "@/plugins/webfontloader";
import axios from 'axios';
import moment from 'moment-timezone'
// @ts-ignore
loadFonts().then();

moment.tz.setDefault("Europe/Berlin");
moment.locale("de");

// @ts-ignore
createApp(App).provide('$moment', moment).use(vuetify).use(pinia, axios).use(Vue3Signature).mount('#app')
