import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import {useFormStore} from "@/stores/FormStore";
import {MaskModel} from "@/models/Mask.model";
import {API_URL} from "@/services/Helper.service";

export const useMaskStore = defineStore('MaskStore', {

    state: () => {

        const maskData: MaskModel[] = [];

        return {

            maskData,

        };
    },

    actions: {


        async getMasksByPriceModelTypeId(price_mode_type_id: any) {


            console.log("fetching Masks");

            const data = await axiosInstance.get(API_URL + '/api/mask/priceModelTypeId/' + price_mode_type_id, {
                headers: {
                    'Authorization': `token`
                }
            }).then((response) => {
                if (response.status !== 200) {
                    throw new Error(response.statusText);
                }
                this.maskData = response.data;
            });
        },
    },

    getters: {
        getMaskObjById: (state) => (maskId: any) => {

            const maskStore = useMaskStore();

            return maskStore.maskData.filter((el: any) => {
                return el?.id === maskId;
            });
        },
        getMaskObjByPageId: (state) => (pageId: any): MaskModel => {

            const maskStore = useMaskStore();
            const formStore = useFormStore();

            const filteredMaskItems: MaskModel[] = maskStore.maskData.filter((el: MaskModel) => {
                return el.page_id === pageId && el?.price_model_type_id === formStore.priceModelData.price_model_type_id;
            });

            return filteredMaskItems[0];
        },
    },
    persist: {
        storage: sessionStorage
    }
});