<template>
  <v-card class="content-container">
    <v-card-text class="content-text d-flex flex-column align-center">

      <ClientCostHeader/>

      <ProductDescriptionLinkButton/>

      <v-form>
        <div style="border-bottom: 1px solid #2E6F82" class="d-flex flex-row align-self-start">

          <div class="d-flex align-end" style="width: 25%;">Anzahl Klienten</div>

          <div class="service-header-content" style="border-top-left-radius: 5px; background: #eaf5f3">

            <h2 class="main-color-1">Basic</h2>
            <p class="b2">Support von <br> 09:00 bis 15:00</p>
          </div>

          <div class="service-header-content"
               style="background: #cfe9e4">

            <h2 class="main-color-1">Comfort</h2>
            <p class="b2" style="margin-bottom: 8px">Support von <br> 08:00 bis 17:00 Uhr </p>
            <p class="b2"> Reaktion je nach <br> Klassifizierung binnen <br> 2 bis 24 Stunden</p>

          </div>
          <div class="service-header-content"
               style="border-top-right-radius: 5px; background: #aedbd3">

            <h2 class="main-color-1">Premium</h2>
            <p style="margin-bottom: 8px;" class="b2">Support von <br> 08:00 bis 17:00</p>
            <p class="b2"> Reaktion je nach <br> Klassifizierung binnen <br> 1 bis 8 Stunden</p>

          </div>
        </div>

        <div class="d-flex flex-row align-self-start" v-for="(n, i) in getRowAmount()" :key="n">

          <ServiceContentDescriptionItem :product="getDataByColumn(1)[i]"/>

          <ServiceContentItem
              background-color="#eaf5f3"
              :product="getDataByColumn(1)[i]"
          />

          <ServiceContentItem
              background-color="#cfe9e4"
              :product="getDataByColumn(2)[i]"
          />

          <ServiceContentItem
              background-color="#aedbd3"
              :product="getDataByColumn(3)[i]"
          />
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions class="actions-container">
      <ButtonStandardBack/>
      <ButtonStandardContinue :prevent-continue="hasValidationFailed()"/>
    </v-card-actions>
  </v-card>

</template>

<script>
import {useFormStore} from "@/stores/FormStore";
import ProductDescriptionLinkButton from "@/components/ProductDescriptionLinkButton.vue";
import ServiceContentItem from "@/components/clientModel/items/FormStep2/ServiceContentItem.vue";
import ServiceContentDescriptionItem from "@/components/clientModel/items/FormStep2/ServiceContentDescriptionItem.vue";
import ButtonStandardContinue from "@/components/clientModel/items/ButtonStandardContinue.vue";
import ButtonStandardBack from "@/components/clientModel/items/ButtonStandardBack.vue";
import ClientCostHeader from "@/components/clientModel/items/ClientCostHeader.vue";

export default {
  components: {
    ClientCostHeader,ProductDescriptionLinkButton,
    ButtonStandardBack, ButtonStandardContinue, ServiceContentDescriptionItem, ServiceContentItem
  },

  data() {
    return {
      productDescriptionDialog: false,
      preventionMessage: ''
    };
  },

  setup() {
    const formStore = useFormStore();
    return {formStore}
  },

  methods: {

    hasValidationFailed() {
      return false;
    },

    async validate() {
      const {valid} = await this.$refs.form.validate()

      if (valid) alert('Form is valid')
    },

    getDataByColumn(columnId) {

      return this.currentMaskItems.filter((obj) => {
        return obj.column === columnId;
      }).sort((a, b) => {
        return a.order - b.order;
      });
    },
    getRowAmount() {

      let biggestColumnId = 0
      for (const element of this.currentMaskItems) {
        if (element.column > biggestColumnId) {
          biggestColumnId = element.column;
        }
      }
      return Number(biggestColumnId);
    }
  },

  computed: {
    currentMaskItems() {
      return this.formStore.getProductsByMaskId().filter((el) => {
        return el.target_group_id === this.formStore.chosenTargetGroup;
      });
    }
  },
}
</script>

<style scoped>

form {
  width: 100%;
}

.service-header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px;
  width: 25%;
}

.service-header-content h2 {
  margin-bottom: 8px;
}

.button-produktbeschreibung {
  background-color: #78C6B8;
  color: white;
  margin-right: 4px;
}

.button-produktbeschreibung img {
  min-height: 24px;
  min-width: 24px;
  margin-right: 6px;
}

.step-2-item-header-text {
  margin-bottom: 8px;
}


</style>
