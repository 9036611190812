<template>
  <v-card class="content-container">
    <v-card-text class="content-text d-flex flex-column align-center">

      <ClientCostHeader/>

      <div class="d-flex flex-column w-100 pr-4 pl-4 mt-6">
        <div class="d-flex justify-space-between">
          <p class="b1 main-color-1">Module zur individuellen Auswahl</p>
          <p class="main-color-1">je Klient</p>
        </div>

        <div v-for="product in clientsNotIntensive" :key="product">

          <ModuleContentItem :product="product"/>
        </div>

        <div v-if="clientsNotIntensiveSingleOrder.length > 0">

          <div class="d-flex justify-space-between mt-4 mb-4">
            <p class="b1 main-color-1">Module zur individuellen Auswahl</p>
            <p class="main-color-1">einmalig</p>
          </div>


          <div v-for="product in clientsNotIntensiveSingleOrder" :key="product">

            <ModuleContentItem :product="product"/>

          </div>
        </div>
      </div>
      <div class="d-flex flex-row w-100 pa-4 pb-0">

        <div class="d-flex flex-column mr-auto"><p class="step-3-item-header-text">Anzahl der Klienten (intensiv):</p>

          <v-text-field type="number"
                        min="0"
                        step="1"
                        onfocus="this.previousValue = this.value"
                        onkeydown="this.previousValue = this.value"
                        oninput="validity.valid || (value = this.previousValue)"
                        v-model="formStore.numberOfIntenseClients" variant="outlined" density="compact"
                        hide-details="auto"></v-text-field>
        </div>
      </div>


      <div class="d-flex flex-column w-100 pr-4 pl-4 mt-6">
        <div class="d-flex justify-space-between">
          <p class="b1 main-color-1">Module zur individuellen Auswahl</p>
          <p class="main-color-1">je Klient</p>
        </div>


        <div v-for="product in clientsIntensive" :key="product">

          <ModuleContentItem :product="product"/>
        </div>


        <div v-if="clientsIntensiveSingleOrder.length > 0">

          <div class="d-flex justify-space-between mt-4 mb-4">
            <p class="b1 main-color-1">Module zur individuellen Auswahl</p>
            <p class="main-color-1">einmalig</p>
          </div>

          <div v-for="product in clientsIntensiveSingleOrder" :key="product">

            <ModuleContentItem :product="product"/>
          </div>
        </div>

      </div>

    </v-card-text>

    <v-card-actions class="actions-container">
      <ButtonStandardBack/>
      <ButtonStandardContinue/>
    </v-card-actions>
  </v-card>


</template>

<script>

import ButtonStandardBack from "@/components/clientModel/items/ButtonStandardBack.vue";
import ButtonStandardContinue from "@/components/clientModel/items/ButtonStandardContinue.vue";
import ModuleContentItem from "@/components/clientModel/items/FormStep3/ModuleContentItem.vue";
import ClientCostHeader from "@/components/clientModel/items/ClientCostHeader.vue";
import {useFormStore} from "@/stores/FormStore";

export default {
  components: {
    ClientCostHeader, ModuleContentItem, ButtonStandardContinue, ButtonStandardBack
  },

  setup() {
    const formStore = useFormStore();

    return {formStore}
  },

  methods: {

  },

  computed: {
    clientsIntensive() {
      return this.formStore.getProductsByMaskId().filter((el) => {
        return Number(el.intensive_clients) === 1;
      }).filter((el) => {
        return Number(el.price_unique === 0)
      })
    },
    clientsIntensiveSingleOrder() {
      return this.formStore.getProductsByMaskId().filter((el) => {
        return Number(el.intensive_clients) === 1;
      }).filter((el) => {
        return Number(el.price_unique !== 0)
      })
    },
    clientsNotIntensive() {
      return this.formStore.getProductsByMaskId().filter((el) => {
        return Number(el.intensive_clients) === 0;
      }).filter((el) => {
        return Number(el.price_unique === 0)
      })
    },
    clientsNotIntensiveSingleOrder() {
      return this.formStore.getProductsByMaskId().filter((el) => {
        return Number(el.intensive_clients) === 0;
      }).filter((el) => {
        return Number(el.price_unique !== 0)
      })
    }
  }
}

</script>


<style scoped>


</style>
