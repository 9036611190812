<template>

  <div v-html="maskStore.getMaskObjByPageId(formStore.currentStep).description"></div>


</template>

<script>

import {useMaskStore} from "@/stores/MaskStore";
import {useFormStore} from "@/stores/FormStore";

export default{

  setup(){
    const maskStore = useMaskStore();
    const formStore = useFormStore();
    return {maskStore, formStore}
  },

}

</script>