<template>
  <div class="d-flex flex-column justify-center align-center h-100 w-100">

    <div class="spinner"></div>
    <h3 class="mt-3">Lade Produkte...</h3>

  </div>
</template>
<script>

import {useFormStore} from "@/stores/FormStore";
import * as HelperService from "@/services/Helper.service";
import {useBusinessTypeStore} from "@/stores/BusinessTypeStore";

export default {

  setup() {
    const formStore = useFormStore();
    const businessTypeStore = useBusinessTypeStore();

    return {formStore, businessTypeStore}
  },

  data() {
    return {
      isLoading: false
    }
  },

  created() {
    let urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('quoteId') && urlParams.has('salesId')) {
      this.formStore.quoteId = urlParams.get('quoteId');
      this.formStore.salesId = urlParams.get('salesId');
    }

  },

  async mounted() {
    await HelperService.preFetchBusinessTypes();
    this.businessTypeStore.chosenBusinessType = this.businessTypeStore.businessTypeData[0];
    this.formStore.currentStep++;

    /*
      const salesforce_access_token = await this.formStore.generateToken();
      console.log(salesforce_access_token);
      await this.formStore.getQuoteResponsibleName(this.formStore.quoteId,salesforce_access_token);
      */

    //await this.formStore.testPatch(this.formStore.quoteId,salesforce_access_token);

  },
}

</script>


<style scoped>


</style>
