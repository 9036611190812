import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import {useFormStore} from "@/stores/FormStore";
import {MaskModel} from "@/models/Mask.model";
import {BusinessTypeModel} from "@/models/BusinessType.model";
import {API_URL} from "@/services/Helper.service";

export const useBusinessTypeStore = defineStore('BusinessTypeStore', {

    state: () => {

        const businessTypeData: BusinessTypeModel[] = [];
        const chosenBusinessType: BusinessTypeModel = {} as BusinessTypeModel;

        return {

            businessTypeData,
            chosenBusinessType,

        };
    },

    actions: {

        async getBusinessTypes() {

            console.log("fetching business types");

            const data = await axiosInstance.get(API_URL + '/api/businessType', {
                headers: {
                    'Authorization': `token`
                }
            }).then((response) => {
                if (response.status !== 200) {
                    throw new Error(response.statusText);
                }
                this.businessTypeData = response.data;
            });
        },
    },

    getters: {},
    persist: {
        storage: sessionStorage
    }
});