<template>
  <v-card class="content-container">
    <v-card-text class="content-text d-flex flex-column align-center">

      <div class="d-flex flex-row w-100 align-center">
        <div class="w-33"><ProductDescriptionLinkButton/></div>
        <div class="w-33 text-center"><h2 class="single-form-page-header main-package-color-1">Passen Sie den gewählten Tarif Ihren Bedürfnissen an!</h2></div>
        <div class="w-33"><ClientCostHeader/> </div>
      </div>

      <v-row class="justify-center align-end mt-4 w-100">
        <v-col
            class=""
            v-for="(product, index) in formStore.getProductsByMaskId()"
            :key="index"
            cols="12"
            sm="3"
            md="3"
            lg="3"
        >
          <PackageItem :product="product"/>
        </v-col>
      </v-row>

    </v-card-text>
    <v-card-actions class="d-flex flex-column">

      <MaskDescription/>

      <div class="d-flex w-100 actions-container">
        <ButtonStandardBack/>
        <ButtonStandardContinue :prevent-continue="hasValidationFailed()"/>
      </div>
    </v-card-actions>
  </v-card>

</template>

<script>
import {useFormStore} from "@/stores/FormStore";
import ButtonStandardContinue from "@/components/clientModel/items/ButtonStandardContinue.vue";
import ButtonStandardBack from "@/components/clientModel/items/ButtonStandardBack.vue";
import ClientCostHeader from "@/components/packageModel/items/ClientCostHeader.vue";
import {usePackageModelProductStore} from "@/stores/PackageModelProductsStore";
import PackageItem from "@/components/packageModel/items/PackageItem.vue";
import ProductDescriptionLinkButton from "@/components/ProductDescriptionLinkButton.vue";
import MaskDescription from "@/components/packageModel/items/MaskDescription.vue";

export default {
  components: {
    MaskDescription,
    ProductDescriptionLinkButton,
    PackageItem,
    ClientCostHeader,
    ButtonStandardBack, ButtonStandardContinue
  },

  data() {
    return {
      productDescriptionDialog: false,
      preventionMessage: ''
    };
  },

  setup() {
    const formStore = useFormStore();
    const packageModelProductStore = usePackageModelProductStore();
    return {formStore, packageModelProductStore}
  },

  methods: {

    hasValidationFailed() {
      return false;
    },

    async validate() {
      const {valid} = await this.$refs.form.validate()

      if (valid) alert('Form is valid')
    },

    getDataByColumn(columnId) {

      return this.currentMaskItems.filter((obj) => {
        return obj.column === columnId;
      }).sort((a, b) => {
        return a.order - b.order;
      });
    },
    getRowAmount() {

      let biggestColumnId = 0
      for (const element of this.currentMaskItems) {
        if (element.column > biggestColumnId) {
          biggestColumnId = element.column;
        }
      }
      return Number(biggestColumnId);
    }
  },

  computed: {
    currentMaskItems() {
      return this.formStore.getProductsByMaskId().filter((el) => {
        return el.target_group_id === this.formStore.chosenTargetGroup;
      });
    },
  },
}
</script>

<style scoped>

form {
  width: 100%;
}

.service-header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px;
  width: 25%;
}

.service-header-content h2 {
  margin-bottom: 8px;
}

.button-produktbeschreibung {
  background-color: #78C6B8;
  color: white;
  margin-right: 4px;
}

.button-produktbeschreibung img {
  min-height: 24px;
  min-width: 24px;
  margin-right: 6px;
}

.step-2-item-header-text {
  margin-bottom: 8px;
}

.col-max-width{
  min-width: 231px;
}


</style>
