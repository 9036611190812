import {useFormStore} from "@/stores/FormStore";
import {useClientModelProductStore} from "@/stores/ClientModelProductsStore";
import {usePackageModelProductStore} from "@/stores/PackageModelProductsStore";
import {useMaskStore} from "@/stores/MaskStore";
import {useBusinessTypeStore} from "@/stores/BusinessTypeStore";

//export const API_URL = process.env.VUE_APP_API_URL;
export const API_URL = "https://backoffice-backend.jg-agency.de";

export async function fetchApplicationDataOnStart() {

    const formStore = useFormStore();
    const clientModelProductsStore = useClientModelProductStore();
    const packageModelProductStore = usePackageModelProductStore();
    const maskStore = useMaskStore();
    const businessTypeStore = useBusinessTypeStore();

    console.log('start fetching PriceModelData');
    await formStore.getPriceModelById(businessTypeStore.chosenBusinessType.price_model_id);

    if (Object.keys(formStore.priceModelData).length === 0) {
        alert("Kein aktives PriceModel konfiguriert!y Prozess kann nicht gestartet werden")
    }

    console.log('start fetching productData by priceModelId');
    if(formStore.priceModelData.price_model_type_id === 1){
        await clientModelProductsStore.getClientModelProductsByPriceModelId(formStore.priceModelData.id);
    } else {
        await packageModelProductStore.getPackageModelProductsByPriceModelId(formStore.priceModelData.id);
    }

    console.log('start fetching MaskData by priceModelId');
    await maskStore.getMasksByPriceModelTypeId(formStore.priceModelData.price_model_type_id);

}

export async function preFetchBusinessTypes() {

        const businessTypeStore = useBusinessTypeStore();

        console.log('start fetching BusinessTypes');
        await businessTypeStore.getBusinessTypes();

}

export function convertToGermanCurrency(price: number) {
    return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(price)
}

export const convertToFormData = (obj: any, formData = new FormData(), namespace = "") => {
    for (const property in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(property)) {
            const formKey = namespace ? `${namespace}[${property}]` : property;

            // Überprüfen Sie, ob der Wert leer oder undefined ist.
            if (obj[property] === undefined || obj[property] === null || obj[property] === '') {
                continue;
            }

            if (typeof obj[property] === 'object' && !(obj[property] instanceof File) && !(obj[property] instanceof Blob)) {
                convertToFormData(obj[property], formData, formKey);
            } else {
                formData.append(formKey, obj[property]);
            }
        }
    }
    return formData;
};
