<template>

  <div class="d-flex align-self-start pl-4 mt-8 mb-8">
    <button @click="formStore.openPriceModelPdfUrl('product_description_file')"
            class="button-produktbeschreibung ufo-small d-flex align-center justify-center">
      <img
          :src="require('@/assets/images/info.svg')" alt="">
      <span class="poppins semi-bold">Produktbeschreibung</span>
    </button>
  </div>



</template>
<script>


import {useFormStore} from "@/stores/FormStore";

export default {

  setup(){

    const formStore = useFormStore();

    return {formStore}

  },

  props: ['product'],

}
</script>

<style scoped>


.button-produktbeschreibung {
  background-color: #78C6B8;
  color: white;
  margin-right: 4px;
}

.button-produktbeschreibung img {
  min-height: 24px;
  min-width: 24px;
  margin-right: 6px;
}

</style>