import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
// @ts-ignore
import currencyFormatter from "currency-formatter";
import {useClientModelProductStore} from "@/stores/ClientModelProductsStore";
import {PriceModelModel} from "@/models/PriceModel.model";
import {usePackageModelProductStore} from "@/stores/PackageModelProductsStore";
import {ContactDataModel} from "@/models/ContactData.model";
import {ConclusionDataModel} from "@/models/ConclusionData.model";
import {useMaskStore} from "@/stores/MaskStore";
import {MaskModel} from "@/models/Mask.model";
import {API_URL, convertToFormData} from "@/services/Helper.service";

export const useFormStore = defineStore('FormStore', {


    state: () => {

        const quoteId: any = null;
        const salesId: any = null;
        const salesMan: any = null;

        const currentStep: number = 0;
        const totalSteps: number = 7;

        const chosenTargetGroup = 1;
        const numberOfClients = 0;
        const numberOfIntenseClients = 0;

        const contactData: ContactDataModel = {paymentMethod: 1, sepaData: {}, cessionDeclaration: {}, contactPerson: {}, address: {}};
        const conclusionData: ConclusionDataModel = {};
        const priceModelData: PriceModelModel = {};

        return {
            numberOfIntenseClients,
            quoteId,
            salesId,
            chosenTargetGroup,
            numberOfClients,
            currentStep,
            totalSteps,
            salesMan,
            contactData,
            priceModelData,
            conclusionData,

        }
    },

    actions: {

        openPriceModelPdfUrl(fileName: string){

            const url = API_URL + '/api/pricemodel/' + this.priceModelData.id + '/file/' + fileName;
            window.open(url, '_blank');
        },

        async getPriceModelById(priceModelId?: number) {

            console.log("fetching PriceModels");

            await axiosInstance.get(API_URL + '/api/pricemodel/' + priceModelId, {
                headers: {
                    'Authorization': `token`
                }
            }).then((response) => {
                if (response.status !== 200) {
                    throw new Error(response.statusText);
                }
                this.priceModelData = response.data;
                this.priceModelData.price_model_type_id = Number(this.priceModelData.price_model_type_id);
            });
        },

        async finishForm(body: any) {
            const formData = convertToFormData(body);

            const data = await axiosInstance.post(API_URL + "/api/contract/finishForm", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (data.status != 200) {
                throw new Error(data?.statusText)
            }

            return data;
        },
    },

    getters: {
        /**
         Gibt die Produkte für die gewählte Zielgruppe und Maske zurück
         */
        getProductsByMaskId: (state) => (pageId?: any) => {

            const formStore = useFormStore();
            const maskStore = useMaskStore();

            if(!pageId){
                pageId = formStore.currentStep;
            }

            const maskToCheck: MaskModel = maskStore.getMaskObjByPageId(pageId);

            if (formStore.priceModelData.price_model_type_id == 1) {
                const clientModelStore = useClientModelProductStore();

                return clientModelStore.clientModelData.filter((el: any) => {
                    return el.mask_id === maskToCheck.id;
                }).sort((a: any, b: any) => {
                    return a.order - b.order;
                });

            } else {

                const packageModelStore = usePackageModelProductStore();

                return packageModelStore.packageModelData.filter((el: any) => {
                    return el.mask_id === maskToCheck.id;
                }).sort((a: any, b: any) => {
                    return a.order - b.order;
                });
            }
        },
        getCheckedProductsByPageId: (state) => (pageId: any) => {

            const formStore = useFormStore();
            const productsByMask = formStore.getProductsByMaskId(pageId)

            return productsByMask.filter((el: any) => {
                return el?.checked === true;
            }).sort((a: any, b: any) => {
                return a.order - b.order;
            });
        }
    },

    persist: {
        storage: sessionStorage
    }
});
