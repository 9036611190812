<template>

  <div class="d-flex flex-column header-container w-100">
    <div class="d-flex flex-row">
      <p class="first-word main-color-1"> od | &nbsp;</p>
      <p class="second-word main-color-2">care</p>
    </div>
    <div>
      <p class="subtitle-text">Pflege unterstützen, organisieren</p>
    </div>
  </div>

</template>


<style scoped>

.subtitle-text{
  font-family: var(--main-font-family);
  font-size: 12px;
  color: var(--main-color-1)
}

.header-container {

  width: 100%;
  background-color: #cddfe6;
  padding: 16px 0 16px 16px;
}

.first-word {
  font-family: 'Poppins', 'sans-serif';
  font-weight: 600;
  font-size: 26px;
}

.second-word {
  font-family: 'Poppins', 'sans-serif';
  font-weight: 300;
  font-size: 26px;
}

</style>


<script>

export default {}


</script>
