<template>
  <v-app>
    <v-app-bar>
      <PageHeader/>
    </v-app-bar>

    <v-main>
      <v-card class="container-card">
        <v-card-title class="container-card-title">
          <ProgressBar :total-steps="formStore.totalSteps" :current-step="formStore.currentStep"/>
        </v-card-title>
        <v-card-text class="container-card-text h-100">

          <HomeContent v-if="formStore.currentStep === 0"/>


          <div v-if="formStore.currentStep === 1">
            <SelectBusinessTypePage/>
          </div>

            <div v-if="formStore.priceModelData.price_model_type_id === 1">
            <div v-if="formStore.currentStep === 2">
              <ClientModelFormStep2/>
            </div>
            <div v-if="formStore.currentStep === 3">
              <ClientModelFormStep3/>
            </div>
            <div v-if="formStore.currentStep === 4">
              <ClientModelFormStep4/>
            </div>
            <div v-if="formStore.currentStep === 5">
              <ClientModelFormStep5/>
            </div>
            <div v-if="formStore.currentStep === 7">
              <ClientModelFormStep7/>
            </div>
          </div>
          <div v-if="formStore.priceModelData.price_model_type_id === 2">
            <div v-if="formStore.currentStep === 2">
              <PackageModelFormStep2/>
            </div>
            <div v-if="formStore.currentStep === 3">
              <PackageModelFormStep3/>
            </div>
            <div v-if="formStore.currentStep === 4">
              <PackageModelFormStep4/>
            </div>
            <div v-if="formStore.currentStep === 5">
              <PackageModelFormStep5/>
            </div>
            <div v-if="formStore.currentStep === 7">
              <PackageModelFormStep7/>
            </div>
          </div>

          <div v-if="formStore.currentStep === 6">
            <ContactDataPage/>
          </div>
          <div v-if="formStore.currentStep === 8">
            <FormFinishedPage/>
          </div>
        </v-card-text>
      </v-card>
    </v-main>
  </v-app>

</template>

<script>
import {useFormStore} from "@/stores/FormStore";
import ProgressBar from "@/components/ProgressBar.vue";
import PageHeader from "@/components/PageHeader.vue";
import HomeContent from "@/views/FormStartPage.vue"

import {useClientModelProductStore} from "@/stores/ClientModelProductsStore";
import ClientModelFormStep2 from "@/views/clientModel/ClientModelFormStep2Page.vue";
import ClientModelFormStep3 from "@/views/clientModel/ClientModelFormStep3Page.vue";
import ClientModelFormStep4 from "@/views/clientModel/ClientModelFormStep4Page.vue";
import ClientModelFormStep5 from "@/views/clientModel/ClientModelFormStep5Page.vue";
import ClientModelFormStep7 from "@/views/clientModel/ClientModelFormStep7Page.vue";

import PackageModelFormStep2 from "@/views/packageModel/PackageModelFormStep2Page.vue";
import PackageModelFormStep3 from "@/views/packageModel/PackageModelFormStep3Page.vue";
import PackageModelFormStep4 from "@/views/packageModel/PackageModelFormStep4Page.vue";
import PackageModelFormStep5 from "@/views/packageModel/PackageModelFormStep5Page.vue";
import PackageModelFormStep7 from "@/views/packageModel/PackageModelFormStep7Page.vue";
import FormFinishedPage from "@/views/FormFinishedPage.vue";
import SelectBusinessTypePage from "@/views/SelectBusinessTypePage.vue";
import ContactDataPage from "@/views/ContactDataPage.vue";


export default {
  components: {
    SelectBusinessTypePage,
    FormFinishedPage,
    ClientModelFormStep2,
    ClientModelFormStep3,
    ClientModelFormStep4,
    ClientModelFormStep5,
    ClientModelFormStep7,

    PackageModelFormStep2,
    PackageModelFormStep3,
    PackageModelFormStep4,
    PackageModelFormStep5,
    PackageModelFormStep7,
    PageHeader,
    HomeContent,
    ProgressBar,
    ContactDataPage,
  },

  setup() {
    const formStore = useFormStore();
    const clientModelStore = useClientModelProductStore();

    return {formStore, clientModelStore}
  },

  async mounted() {

  }
};


</script>

<style>

@import "./assets/css/global.css";

.v-application__wrap {
  min-height: 100vh !important;
  min-height: 100dvh !important;

}

.container-card-text {
  padding: 0;
}

.container-card-title {
  padding: 0;
}

.container-card {
  height: 100%;
  box-shadow: unset;
  background: #F8F9FA;
  padding-left: 16px;
  padding-right: 16px;
}

.v-toolbar {
  box-shadow: unset !important;
}

.v-main {
  background: #F8F9FA;
}


</style>
