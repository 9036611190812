// src/axios.js oder ein ähnlicher Pfad
import axios from 'axios';

const axiosInstance = axios.create({
});

axiosInstance.interceptors.request.use(
    config => {
        // Bearbeiten oder loggen der Anfragekonfiguration
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        // Weitere Fehlerbehandlung
        return Promise.reject(error);
    }
);

export default axiosInstance;
