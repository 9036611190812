<template>

  <div :class="this.class" class="d-flex flex-column flex-grow-1">

    <v-card class="d-flex flex-column flex-grow-1">

      <v-card-title class="pa-4">
        <h2>{{ product.name }}</h2>

      </v-card-title>

      <v-card-text class="d-flex flex-grow-1 inner-text-container">
        <div class="d-flex flex-column justify-center h-100 text pa-2 text-center flex-grow-1" v-html="product.description"/>
      </v-card-text>


      <v-card-actions class="form-card-action">
        <ProductCostFooter :product="product"/>
      </v-card-actions>
    </v-card>

    <v-checkbox class="d-flex flex-grow-0 flex-row justify-center w-100" v-model="updateProduct" density="compact"
                hide-details="auto"/>

    <div v-if="!product?.checked || !product?.allow_order_amount_input" class="text-field-placeholder"></div>

    <v-text-field class="flex-grow-0" v-if="product?.checked && product?.allow_order_amount_input" :rules="positionRule"
                  v-model="updateProductAmount" type="number"
                  variant="outlined" density="compact" hide-details="auto"/>

  </div>

</template>


<script>

import {useFormStore} from "@/stores/FormStore";
import {usePackageModelProductStore} from "@/stores/PackageModelProductsStore";
import ProductCostFooter from "@/components/packageModel/items/ProductCostFooter.vue";

export default {
  components: {ProductCostFooter},

  setup() {
    const formStore = useFormStore();
    const packageModelProductStore = usePackageModelProductStore();

    return {formStore, packageModelProductStore}
  },

  data() {
    return {
      positionRule: [
        v => !!v || 'Pflichtfeld',
        v => {
          const regex = /^(\d{1,2})$/;
          if (!regex.test(v)) {
            return 'Maximal zweistellig';
          }
          return true;
        }
      ],
    }
  },


  computed: {

    updateProduct: {
      get() {
        return this.product?.checked;
      },
      set(newSelectedValue) {

        console.log(this.product);

        let myNewProduct = this.product;

        if (newSelectedValue === true) {
          myNewProduct.orderedAmount = 1;
        }
        myNewProduct.checked = newSelectedValue;

        this.packageModelProductStore.updatePackageModelProducts(myNewProduct);

      }
    },
    updateProductAmount: {
      get() {
        return this.product?.orderedAmount || 1;
      },
      set(newSelectedValue) {

        console.log("update Ordered Amount function called")

        let myNewProduct = this.product;
        myNewProduct.orderedAmount = newSelectedValue;

        this.packageModelProductStore.updatePackageModelProducts(myNewProduct);
      }
    },
  },
  props: ['product', 'class']
}


</script>


<style scoped>


.v-card-text {
  padding: 8px;
}

.v-card-title {
  padding: 16px;
  background-color: var(--main-package-color-1);
  color: white;
  font-family: var(--main-font-family);
  text-align: center;
}

.v-card-actions {
  padding: 16px;
  background-color: var(--main-package-color-1);
  color: black;
  font-family: var(--main-font-family);
  text-align: center;
}

.inner-text-container {
  background-color: var(--main-package-color-2);
}

.text {
  background-color: var(--main-package-color-3);
}

.text-field-placeholder {
  height: 40px;
}

</style>