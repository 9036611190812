import {defineStore} from "pinia";
import axiosInstance from "@/plugins/axios";
import {API_URL} from "@/services/Helper.service";

export const useClientModelProductStore = defineStore('ClientModelProductsStore', {

    state: () => {

        const clientModelData: any[] = [];

        const summed_price_unique = 0;
        const summed_price_per_month = 0;
        const summed_price_per_month_current = 0;

        return {

            clientModelData,
            summed_price_unique,
            summed_price_per_month,
            summed_price_per_month_current

        };
    },

    actions: {

        updateClientModelProduct(product: any) {

            const clientModelProductStore = useClientModelProductStore();

            const targetProductIndex = this.clientModelData.find(obj => obj.id === product.id);

            if (targetProductIndex !== -1) {
                clientModelProductStore.$patch((state) => {
                    state.clientModelData[targetProductIndex] = product;
                })
                console.log("product updated! new product", product)
            } else {
                throw Error("Product to update not found");
            }

        },

        async getClientModelProductsByPriceModelId(priceModelId: any) {


            console.log("fetching ClientModelProducts");

            const data = await axiosInstance.get(API_URL + '/api/clientmodel/pricemodel/' + priceModelId + '/active', {
                headers: {
                    'Authorization': `token`
                }
            }).then((response) => {
                if (response.status !== 200) {
                    throw new Error(response.statusText);
                }
                this.clientModelData = response.data;
            });
        },

    },

    getters: {},
    persist: {
        storage: sessionStorage
    }
});