<template>

  <div class="d-flex pt-4 pb-4">
    <div class="descriptionHeaderContainer d-flex">
      <div class="d-flex checkbox-container">
        <p class="b1">{{ product.position }}</p>
      </div>
      <div class="d-flex flex-column pl-4 descriptionContainer mr-auto">
        <h2 class="mb-2">{{ product.name }}</h2>
        <p class="pr-4 h-100">{{ product.description }}</p>
      </div>
    </div>

    <div class="d-flex inputContainer align-start">
      <div class="d-flex justify-space-evenly text-end w-100">
        <p class="b1 input-items">{{ product.orderedAmount }}</p>

        <p class="b1 input-items">{{ convertToGermanCurrency(product.price_unique) }}</p>
        <h3 class="b1 input-items">
          {{ convertToGermanCurrency(product.price_unique * product.orderedAmount) }}</h3>

      </div>
    </div>

  </div>
</template>

<script>

import {useFormStore} from "@/stores/FormStore";
import {convertToGermanCurrency} from "@/services/Helper.service";

export default {
  methods: {convertToGermanCurrency},
  props: ['product'],

  setup() {
    const formStore = useFormStore();

    return {formStore}
  },
  computed: {}
}


</script>


<style scoped>

.descriptionHeaderContainer {
  flex-basis: 50%;
}

.inputContainer {
  flex-basis: 50%;
}

.checkbox-container {
  flex-shrink: 0;
}

.input-items {
  flex-basis: 33%;
  align-self: start;
}

</style>
