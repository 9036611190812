<template>
  <v-card class="content-container">
    <v-card-text class="content-text d-flex flex-column">

      <v-form ref="form" alidate-on="input" class="p-4 prevent" @submit.prevent="submit()">

        <div class="pa-4">
          <h2 class="main-color-1"> Allgemeine Daten </h2>
          <div class="mt-4">
            <div class="d-flex flex-row">
              <div class="d-flex w-50 align-center">
                <p class="b1">Ich benutze bereits eine Pflegesoftware der opta data IT Solutions GmbH*:</p>
                <v-checkbox v-model="formStore.contactData.alreadyHasProduct" type="text" maxlength="255" required
                            class="input-items" variant="outlined" density="compact" hide-details="auto"/>

              </div>

              <div class="d-flex w-50" v-if="formStore.contactData.alreadyHasProduct">
                <div class="d-flex flex-column w-100">
                  <p class="b1 mb-2">Kundennummer (opta data IT Solutions GmbH)</p>
                  <v-text-field :rules="alreadyHasSystemCustomerNumber" v-model="formStore.contactData.customerNumber"
                                type="text" maxlength="255" required
                                class="input-items" variant="outlined" density="compact" hide-details="auto"/>
                </div>
              </div>

            </div>
          </div>


          <div class="mt-4">
            <p class="b1 mb-2">Name des Unternehmens*:</p>
            <v-text-field v-model="formStore.contactData.company" type="text"
                          :rules="requiredRule"
                          class="input-items" variant="outlined" density="compact" hide-details="auto"/>
          </div>

          <div class="mt-4">
            <div class="d-flex flex-row w-100" style="gap: 20px;">
              <div class="w-25">
                <p class="b1 mb-2">Straße*:</p>
                <v-text-field v-model="formStore.contactData.address.street" type="text"
                              :rules="requiredRule"
                              class="input-items" variant="outlined" density="compact" hide-details="auto"/>
              </div>

              <div class="w-25">
                <p class="b1 mb-2">Hausnummer*:</p>
                <v-text-field v-model="formStore.contactData.address.houseNumber" type="text"
                              :rules="requiredRule"
                              class="input-items" variant="outlined" density="compact" hide-details="auto"/>
              </div>

              <div class="w-25">
                <p class="b1 mb-2">PLZ*:</p>
                <v-text-field v-model="formStore.contactData.address.zipCode" type="text"
                              :rules="requiredRule"
                              class="input-items" variant="outlined" density="compact" hide-details="auto"/>
              </div>

              <div class="w-25">
                <p class="b1 mb-2">Ort*:</p>
                <v-text-field v-model="formStore.contactData.address.place" type="text"
                              :rules="requiredRule"
                              class="input-items" variant="outlined" density="compact" hide-details="auto"/>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <div style="gap:30px;" class="d-flex">
              <div class="d-flex flex-column w-100">
                <p class="b1 mb-2">Vorname*:</p>
                <v-text-field v-model="formStore.contactData.contactPerson.firstname" type="text"
                              class="input-items"
                              :rules="requiredRule"
                              variant="outlined" density="compact" hide-details="auto"/>
              </div>
              <div class="d-flex flex-column w-100">
                <p class="b1 mb-2">Nachname*:</p>
                <v-text-field v-model="formStore.contactData.contactPerson.lastname" class="input-items"
                              variant="outlined"
                              :rules="requiredRule"
                              density="compact" hide-details="auto"/>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <p class="b1 mb-2">E-Mail (Rechnungsempfänger)*:</p>
            <v-text-field v-model="formStore.contactData.contactPerson.email_invoice" required type="email"
                          :rules="emailRule"
                          class="input-items" variant="outlined" density="compact" hide-details="auto"/>
          </div>

          <div class="mt-4">
            <div style="gap:30px;" class="d-flex">
              <div class="d-flex flex-column w-100">
                <p class="b1 mb-2">Telefon*:</p>
                <v-text-field :rules="phoneRule" v-model="formStore.contactData.contactPerson.phone" type="tel"
                              class="input-items"
                              variant="outlined" density="compact" hide-details="auto"/>
              </div>
              <div class="d-flex flex-column w-100">
                <p class="b1 mb-2">Fax:</p>
                <v-text-field :rules="faxRule" v-model="formStore.contactData.contactPerson.fax" class="input-items"
                              variant="outlined"
                              density="compact" hide-details="auto"/>
              </div>
            </div>
          </div>


          <div class="mt-4">
            <div style="gap:30px;" class="d-flex">
              <div class="d-flex flex-column w-100">
                <p class="b1 mb-2">IK:</p>
                <v-text-field v-model="formStore.contactData.contactPerson.ik" type="tel" required
                              class="input-items"
                              variant="outlined" density="compact" hide-details="auto"/>
              </div>
              <div class="d-flex flex-column w-33">
                <p class="b1 mb-2">IK-Vergabebescheid als Nachweis hochladen</p>

                <v-file-input class="h-100" accept=".pdf" @change="onIkFileSelected"/>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <div style="gap:30px;" class="d-flex">
              <div class="d-flex flex-column w-100">
                <p class="b1 mb-2">Verbandsmitglied:</p>
                <v-text-field v-model="formStore.contactData.contactPerson.clubMember" type="tel" required
                              class="input-items"
                              variant="outlined" density="compact" hide-details="auto"/>
              </div>
              <div class="d-flex flex-column w-33">
                <p class="b1 mb-2">Mitgliedbescheinigung als Nachweis hochladen</p>
                <v-file-input class="h-100" accept=".pdf" @change="onClubFileSelected"/>
              </div>
            </div>
          </div>

          <div class="mt-6">
            <h2 class="main-color-1"> Zahlungsdaten </h2>
          </div>

          <div class="mt-4">
            <v-radio-group
                v-model="formStore.contactData.paymentMethod"
                inline
            >
              <v-radio
                  label="SEPA"
                  :value="1"
              ></v-radio>
              <v-radio
                  label="Abtretungserklärung"
                  :value="2"
              ></v-radio>

            </v-radio-group>
          </div>

          <div v-if="formStore.contactData.paymentMethod !== 1" class="d-flex">
            <div class="d-flex flex-column pa-4 pt-0">
              <ul>
                <li>Dem Kunden stehen aufgrund monatlicher Leistungsabrechnungen (Rezeptabrechnung)
                  Vergütungsansprüche gegen die opta data Finance GmbH, Berthold-Beitz-Boulevard 461, 45141 Essen zu.
                </li>
                <li>Der odITS stehen aufgrund des vorliegenden Vertragsverhältnisse, die in diesem Vertrag näher
                  benannten, fällig werdenden Zahlungsansprüche zu.
                </li>
                <li>Zur Erfüllung ihrer Zahlungsverpflichtung und zur Sicherung der Forderung der odITS, tritt der
                  Kunde hiermit unwiderruflich seine gegenwärtigen und künftigen Auszahlungsansprüche gegen die Firma
                  opta data Finance GmbH an die Firma odITS ab. Die Firma odITS nimmt die Abtretung an und ist zur
                  sofortigen Offenlegung der Abtretung bei der opta data Finance GmbH berechtigt. Bis auf Widerruf
                  durch die Firma odITS sind die monatlichen Zahlungen aus den Abrechnungsguthaben an diesen Gläubiger
                  vorzunehmen. Der Restbetrag darf an die Kundin ausgekehrt werden.
                </li>
                <li>Sind in einem Abrechnungsmonat keine abtretbaren Vergütungsansprüche gegen die opta data Finance
                  GmbH entstanden, ist eine ggfs. noch bestehende Restforderung in einer Summe sofort zur Rückzahlung
                  an die odITS fällig, sofern dem Kunden nicht ausdrücklich eine Stundung gewährt wird.
                </li>
              </ul>
            </div>
          </div>

          <div v-if="formStore.contactData.paymentMethod === 1">

            <div class="mt-4">
              <p class="b1 mb-2">Kontoinhaber*:</p>
              <v-text-field v-model="formStore.contactData.sepaData.accountHolder" type="text" max="255" required
                            class="input-items" variant="outlined" density="compact" hide-details="auto"/>
            </div>

            <div class="mt-4">
              <p class="b1 mb-2">Adresse*:</p>
              <v-text-field v-model="formStore.contactData.sepaData.address" type="text" max="255" required
                            class="input-items" variant="outlined" density="compact" hide-details="auto"/>
            </div>

            <div class="mt-4">
              <p class="b1 mb-2">BIC:</p>
              <v-text-field :rules="bicRule" v-model="formStore.contactData.sepaData.bic" type="text" min="11" max="11"
                            class="input-items" variant="outlined" density="compact" hide-details="auto"/>
            </div>

            <div class="mt-4">
              <p class="b1 mb-2">IBAN*:</p>
              <v-text-field :rules="ibanRule"
                            v-model="formStore.contactData.sepaData.iban" type="text" required
                            class="input-items" variant="outlined" density="compact" hide-details="auto"/>
            </div>
          </div>

          <div v-if="formStore.contactData.paymentMethod === 2">
            <div class="mt-4">
              <p class="b1 mb-2">Kundennummer (opta data Finance GmbH):</p>
              <v-text-field v-model="formStore.contactData.cessionDeclaration.number" type="number" :counter="25"
                            maxlength="25" minlength="25" class="input-items" variant="outlined"
                            density="compact" hide-details="auto"/>
            </div>
          </div>
        </div>
      </v-form>
    </v-card-text>

    <v-card-actions class="d-flex flex-column justify-center align-center">

      <div v-if="!isFormValid.valid" class="d-flex flex-column mb-4">
        <p class="error-text">Fehler! Kontrolliere die Felder des Formulars auf Hinweise</p>
      </div>

      <MaskDescription/>

      <div class="actions-container">

        <ButtonStandardBack/>
        <button @click="submit" class="button-standard-continue d-flex align-center justify-center">
          <span style="color:white" class="poppins medium mr-2">Weiter</span>
          <img
              :src="require('@/assets/images/arrow-forward.svg')" alt="">
        </button>
      </div>

    </v-card-actions>

  </v-card>


</template>

<script>

import ButtonStandardBack from "@/components/clientModel/items/ButtonStandardBack.vue";
import {useFormStore} from "@/stores/FormStore";
import MaskDescription from "@/components/packageModel/items/MaskDescription.vue";

export default {

  setup() {
    const formStore = useFormStore();

    return {formStore}
  },

  components: {
    MaskDescription,
    ButtonStandardBack
  },

  methods: {

    onIkFileSelected(event) {
      this.formStore.contactData.contactPerson.ikFile = event.target.files[0];
    },

    onClubFileSelected(event) {
      this.formStore.contactData.contactPerson.clubMemberFile = event.target.files[0];
    },

    async submit() {

      this.isFormValid = await this.$refs.form.validate();

      if (this.isFormValid.valid) {
        this.formStore.currentStep++;
      }
    },
  },

  data() {
    return {
      selected: 1,
      dialog: false,
      isFormValid: {valid: true},


      phoneRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/;
          if (!regex.test(v)) {
            return 'Bitte geben Sie eine gültige Telefonnummer ein';
          }
          return true;
        }
      ],

      faxRule: [
        v => {
          if (!v) {
            return true; // Wenn der Wert leer ist, wird die Validierung als erfolgreich betrachtet
          }

          const regex = /^\+?(\d[\d\-\s]+)?(\([\d\-\s]+\))?[\d\-\s]+\d$/;
          if (!regex.test(v)) {
            return 'Bitte geben Sie eine gültige Faxnummer ein';
          }
        }
      ],

      bicRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^[A-Za-z]{6}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/;
          if (!regex.test(v)) {
            return 'Bitte geben Sie eine gültige BIC ein';
          }
          return true;
        }
      ],

      alreadyHasSystemCustomerNumber: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^[a-zA-Z0-9]{3,6}$/;
          if (!regex.test(v)) {
            return 'Bitte geben Sie Ihre Kundenummer mit Maximal 6 stellen ein.';
          }
          return true;
        }
      ],

      ibanRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/;
          if (!regex.test(v)) {
            return 'Bitte geben Sie eine gültige IBAN ein';
          }
          return true;
        }
      ],

      emailRule: [
        v => v !== '' || 'Pflichtfeld',
        v => {
          const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
          if (!regex.test(v)) {
            return 'Bitte geben Sie eine gültige E-Mail ein';
          }
          return true;
        }
      ],

      requiredRule: [
        v => (v !== undefined && v !== '')  || 'Pflichtfeld',
      ],
    }
  },
}

</script>


<style scoped>

.error-text {
  color: red;
}

li{
  font-family: var(--main-font-family);
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.pa-4 {
  padding: 16px; /* 1em entspricht üblicherweise 16px, aber Sie können diesen Wert an Ihre Designanforderungen anpassen */
}

.pt-0 {
  padding-top: 0;
}

ul {
  list-style-type: disc; /* Stellt die Standard-Listenzeichen wieder her */
  margin-top: 0;
}

ul li {
  margin-bottom: 10px; /* Fügt einen unteren Abstand zwischen den Listeneinträgen hinzu */
}


</style>
