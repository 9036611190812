<template>
  <div class="d-flex pt-4 pb-4">
    <div class="descriptionHeaderContainer d-flex">
      <div class="d-flex checkbox-container">
        <p class="b1">{{ product.position }}</p>
      </div>
      <div class="d-flex flex-column pl-4 descriptionContainer mr-auto">
        <h2 class="mb-2">{{ product.name }}</h2>
        <p class="pr-4 h-100">{{ product.description }}</p>
      </div>
    </div>

    <div class="d-flex inputContainer align-start">
      <div class="d-flex justify-space-evenly text-center w-100">
        <v-text-field type="number"
                      min="0"
                      step="1"
                      onfocus="this.previousValue = this.value"
                      onkeydown="this.previousValue = this.value"
                      oninput="validity.valid || (value = this.previousValue)"
                      v-model="updateProduct" class="input-items" variant="outlined"
                      density="compact" hide-details="auto"></v-text-field>


        <h3 class="input-items align-self-center">{{ convertToGermanCurrency(product.price_unique)}}</h3>

        <v-text-field :model-value="calculateAndFormatTotalPrice(this.product?.orderedAmount, product.price_unique)"
                      :readonly="true" class="input-items" variant="outlined" density="compact"
                      hide-details="auto"></v-text-field>
      </div>
    </div>
  </div>
</template>


<script>

import {useFormStore} from "@/stores/FormStore";
import {useClientModelProductStore} from "@/stores/ClientModelProductsStore";

export default {
  props: ['product'],

  setup() {
    const formStore = useFormStore();
    const clientModelProductsStore = useClientModelProductStore();

    return {formStore, clientModelProductsStore}
  },

  methods: {

    calculateAndFormatTotalPrice(multiplier, price) {

      if(multiplier  && price){
        return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(multiplier * price)
      } else {
        return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(0.00)
      }
    },
    convertToGermanCurrency(price) {
      return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(price)
    },
  },
  computed:{
    updateProduct:{
      get(){
        return this.product?.orderedAmount;
      },
      set(newAmount){

        let myNewProduct = this.product;
        myNewProduct.checked = newAmount > 0;

        myNewProduct.orderedAmount = Number(newAmount);

        this.clientModelProductsStore.updateClientModelProduct(myNewProduct)
      }
    }
  }
}


</script>


<style scoped>

.descriptionHeaderContainer {
  flex-basis: 50%;
}

.inputContainer {
  flex-basis: 50%;
}

.checkbox-container {
  flex-shrink: 0;
}

.input-items {
  flex-basis: 33%;
  align-self: start;
}

</style>
