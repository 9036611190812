<template>

  <div class="d-flex pt-4 pb-4">
    <div style="flex-basis: 120px">
      <v-checkbox v-model="updateProduct" class="formStep3CheckBox"
                  density="compact" hide-details="auto" :label="product.position"></v-checkbox>
    </div>
    <div class="d-flex flex-column descriptionContainer mr-auto">
      <h2 class="mb-2">{{ product.name }}</h2>
      <p>{{ product.description }}</p>
    </div>

    <h3>{{ convertToGermanCurrency(getActivePrice) }}</h3>
  </div>
</template>


<script>

import {useFormStore} from "@/stores/FormStore";
import {useClientModelProductStore} from "@/stores/ClientModelProductsStore";
import {convertToGermanCurrency} from "@/services/Helper.service";

export default {
  methods: {convertToGermanCurrency},
  props: ['product'],

  setup() {
    const formStore = useFormStore();
    const clientModelProductStore = useClientModelProductStore()

    return {formStore, clientModelProductStore}
  },
  computed:{
    updateProduct:{
      get(){
        return this.product?.checked;
      },
      set(newSelectedValue){

        let myNewProduct = this.product;
        myNewProduct.checked = newSelectedValue;

        this.clientModelProductStore.updateClientModelProduct(myNewProduct);

      }
    },
    getActivePrice(){
      if(this.product.price_per_client > 0){
        return Number(this.product.price_per_client);
      } else {
        return Number(this.product.price_unique);
      }
    }
  }
}

</script>

<style scoped>

.descriptionContainer {
  padding-top: 2px;
}

</style>
