<template>

  <div class="d-flex flex-column align-start" style="width: 25%">
    <h3 class="text-color" style="margin-bottom: 4px">{{ product?.name }}</h3>
    <p class="b2">{{ product?.description }}</p>

  </div>

</template>


<script>

export default {
  props: ['product']
}


</script>
