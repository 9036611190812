<template>
  <v-card class="content-container">
    <v-card-text class="content-text d-flex flex-column align-center">
      <v-form>
        <div v-if="formStore.quoteId !== null && formStore.salesId !== null">
          <p>Vertriebsmitarbeiter:in</p>
          <v-text-field v-model="formStore.salesMan" :readonly="isReadOnly" :value="formStore.salesMan"
                        style="margin-bottom: 32px"/>
        </div>

        <h3 style="margin-bottom: 16px" class="text-center main-color-1">Wählen Sie Ihre Unternehmensform:</h3>

        <v-radio-group
            v-model="businessTypeStore.chosenBusinessType">

          <v-radio
              v-for="(option) in businessTypeStore.businessTypeData"
              :key="option.id"
              :label="option.name"
              :value="option"
              class="b1 text-color"/>

        </v-radio-group>
      </v-form>
    </v-card-text>
    <v-card-actions>

    <div class="d-flex flex-column align-center w-100">
      <div class="mt-4" style="gap: 0 !important;">

        <button @click="continueSteps()" class="button-standard-continue d-flex align-center justify-center">
          <span class="poppins medium">Weiter</span>
          <span v-if="isLoading" class="ml-3 spinner"></span>
          <img v-if="!isLoading"
              :src="require('@/assets/images/arrow-forward.svg')" alt="">
        </button>

      </div>

    </div>


    </v-card-actions>
  </v-card>
</template>

<script>
import {useFormStore} from "@/stores/FormStore";
import {useClientModelProductStore} from "@/stores/ClientModelProductsStore";
import {useBusinessTypeStore} from "@/stores/BusinessTypeStore";
import {fetchApplicationDataOnStart} from "@/services/Helper.service";

export default {
  setup() {
    const formStore = useFormStore();
    const clientModelProductsStore = useClientModelProductStore();
    const businessTypeStore = useBusinessTypeStore();
    return {formStore, clientModelProductsStore, businessTypeStore}
  },

  computed: {
    isReadOnly() {
      return this.formStore.salesId !== '1';
    }
  },

  methods: {
    async continueSteps() {
      this.isLoading = true;
      await fetchApplicationDataOnStart();
      this.isLoading = false;

      this.formStore.currentStep++;
    }
  },

  data() {
    return {
      isLoading: false
    }
  },
}
</script>

<style scoped>
form {
  margin-top: 64px;
}
</style>
