<template>

  <div class="d-flex flex-row w-100 pa-4 pb-0 justify-end">

    <div v-if="formStore.priceModelData.price_model_type_id === 1" class="d-flex flex-column mr-auto"><p
        class="item-header-text">Anzahl der Klienten:</p>

      <v-text-field type="number"
                    min="0"
                    step="1"
                    onfocus="this.previousValue = this.value"
                    onkeydown="this.previousValue = this.value"
                    oninput="validity.valid || (value = this.previousValue)" v-model="formStore.numberOfClients"
                    :readonly="formStore.currentStep > 2" variant="outlined"
                    density="compact" hide-details="auto"></v-text-field>
    </div>

    <div class="d-flex flex-column mr-4 text-center">
      <p class="item-header-text">einmalig</p>
      <div class="ufo-big main-color-1"><p style="padding-top: 10px; padding-bottom: 10px;">{{ convertToGermanCurrency(clientModelProductsStore.summed_price_unique) }}</p></div>
    </div>

    <div class="d-flex flex-column text-center">
      <p class="item-header-text">monatlich</p>
      <div class="ufo-big main-color-1">

        <div class="d-flex flex-column"></div>
        <p>min.: {{ convertToGermanCurrency(clientModelProductsStore.summed_price_per_month) }}</p>
        <p>aktl.: {{ convertToGermanCurrency(clientModelProductsStore.summed_price_per_month_current) }}</p>
      </div>
    </div>

    {{singleCosts()}}
    {{monthlyCosts()}}

  </div>

</template>


<script>

import {useFormStore} from "@/stores/FormStore";
import {useClientModelProductStore} from "@/stores/ClientModelProductsStore";
import {useMaskStore} from "@/stores/MaskStore";
import {convertToGermanCurrency} from "@/services/Helper.service";

export default {

  setup() {
    const formStore = useFormStore();
    const maskStore = useMaskStore();
    const clientModelProductsStore = useClientModelProductStore();
    return {formStore, clientModelProductsStore, maskStore}
  },

  methods: {
    convertToGermanCurrency,

    monthlyCosts() {
      console.log("monthly costs updating..");

      let current = 0;
      let min = 0;

      this.clientModelProductsStore.clientModelData.forEach(product => {
        if (product?.price_per_client) {
          if (product.price_per_client !== 0 && product.price_unique <= 0) {
            if (product?.checked) {

              if (product.intensive_clients) {
                current += product.price_per_client * this.formStore.numberOfIntenseClients;
                min += product.price_per_client * this.formStore.numberOfIntenseClients;
              } else {
                // Wenn es eine Minimum Amount gibt muss dieses berücksichtig werden

                if (product.minimum_amount > 0) {
                  min += product.price_per_client * product.minimum_amount;

                  if (this.formStore.numberOfClients > product.minimum_amount) {
                    current += product.price_per_client * this.formStore.numberOfClients;
                  } else {
                    current += product.price_per_client * product.minimum_amount;
                  }
                } else {
                  console.log("keine minimum amount");
                  min += product.price_per_client * this.formStore.numberOfClients;
                  current += product.price_per_client * this.formStore.numberOfClients
                }
              }
            }
          }
        }
      });

      current = current.toFixed(2);
      min = min.toFixed(2);

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.clientModelProductsStore.summed_price_per_month = min;
      this.clientModelProductsStore.summed_price_per_month_current = current;
    },

    singleCosts() {
      console.log("single cost updating..");
      let total = 0;

      this.clientModelProductsStore.clientModelData.forEach(product => {
        console.log("update price_unique (Einmalig)");

        if (product?.price_unique && product.checked) {
          if (product.price_unique > 0) {
            if (product?.orderedAmount) {
              total += product.orderedAmount * product.price_unique;
            } else {
              total += product.price_unique;
            }
          }
        }
      });

      total = total.toFixed(2)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.clientModelProductsStore.summed_price_unique = total;
    },
  },
}

</script>

<style scoped>

.item-header-text {
  margin-bottom: 8px;
}

</style>
