<template>

  <p>{{ preventionMessage }}</p>

  <button @click="continueSteps()" class="button-standard-continue d-flex align-center justify-center">
    <span class="poppins medium">Weiter</span>
    <img
        :src="require('@/assets/images/arrow-forward.svg')" alt="">
  </button>

</template>


<script>

import {useFormStore} from "@/stores/FormStore";
import {useClientModelProductStore} from "@/stores/ClientModelProductsStore";
export default {

  props: ['preventContinue', 'preventionMessage'],

  setup() {
    const formStore = useFormStore();
    const clientModelProductsStore = useClientModelProductStore();
    return {formStore, clientModelProductsStore}
  },

  methods: {
    async continueSteps() {

      if (!this.preventContinue) {
        this.formStore.currentStep++;
        return;
      }

      if (this.preventContinue === false) {
        this.formStore.currentStep++;
      }
    }
  }
}

</script>

<style scoped>

span {
  padding-right: 4px;
  font-size: 14px
}

</style>
