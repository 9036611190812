<template>

  <div class="d-flex pt-4 pb-4">
    <div style="flex-basis: 70px">
      <p class="positionTitle">{{ product.position }}</p>
    </div>
    <div class="d-flex flex-column descriptionContainer mr-auto">
      <h2 class="mb-2">{{ product.name }}</h2>
      <div v-if="maskId <= 2" class="d-flex flex-column">
        <div class="d-flex flex-row">
          <p><strong><u>Module: </u></strong></p>
          <div v-html="product.description_module"/>
        </div>

        <div class="d-flex flex-row mt-1">
          <p><strong><u>Lizenzen: </u></strong></p>
          <div v-html="product.description_licence"/>
        </div>

        <div class="d-flex flex-row mt-1">
          <p><strong><u>Service: </u></strong></p>
          <div v-html="product.description_service"/>
        </div>

        <div class="d-flex flex-row mt-1">
          <p><strong><u>Laufzeit: </u></strong></p>
          <div v-html="product.description_runtime"/>
        </div>
      </div>
      <div v-else>
        <div class="d-flex flex-column descriptionContainer mr-auto">
          <div v-html="product.description" class="mb-2"/>
        </div>
      </div>
    </div>

    <div class="d-flex inputContainer align-start">
      <div class="d-flex justify-space-evenly text-end w-100">
        <h3 v-if="product.orderedAmount > 0 && maskId === 3" class="input-items">{{ product.orderedAmount }} Stk.</h3>
        <h3 v-else class="input-items"></h3>
        <h3 v-if="maskId !== 3" class="input-items">{{ convertToGermanCurrency(product.price_unique) }}</h3>
        <h3 class="input-items">{{ convertToGermanCurrency(product.price_per_month) }}</h3>
        <h3 v-if="maskId === 3" class="input-items">{{ convertToGermanCurrency(product.price_per_month * product.orderedAmount) }}</h3>
      </div>
    </div>
  </div>
</template>


<script>

import {useFormStore} from "@/stores/FormStore";
import {convertToGermanCurrency} from "@/services/Helper.service";

export default {
  methods: {convertToGermanCurrency,

    getOrderedAmount(orderedAmount){
    if(!orderedAmount){
      return 1;
    } else {
      return orderedAmount;
    }

    }
  },
  props: ['product', 'maskId'],

  setup() {
    const formStore = useFormStore();

    return {formStore}
  },

}


</script>


<style scoped>

.descriptionContainer {
  padding-top: 2px;
}

.positionTitle {
  font-weight: 300;
  margin-top: 2px;
  font-size: 1.1rem;
}

.inputContainer {
  flex-basis: 50%;
}

.input-items {
  flex-basis: 50%;
  align-self: start;
}

p{
  margin-right: 5px
}

</style>
