import {useFormStore} from "@/stores/FormStore";
import {usePackageModelProductStore} from "@/stores/PackageModelProductsStore";
import moment from "moment-timezone";
import {useClientModelProductStore} from "@/stores/ClientModelProductsStore";
import {useBusinessTypeStore} from "@/stores/BusinessTypeStore";


export async function finishFormClientModel(signature: string) {

    const formStore = useFormStore();
    const clientModelStore = useClientModelProductStore();
    const businessTypeStore = useBusinessTypeStore();

    const checkedProducts: any[] = [];
    let paymentData = {};

    if (formStore.contactData.paymentMethod === 1) {
        paymentData = formStore.contactData.sepaData;
    } else {
        paymentData = formStore.contactData.sepaData;
    }

    clientModelStore.clientModelData.forEach((product) => {
        if (product?.checked === true) {
            checkedProducts.push(product);
        }
    });

    const clientModelObject = {
        salesForce:
            {
                quoteId: formStore.quoteId,
                salesId: formStore.salesId,
            },
        customer: {
            salesForceId: null, //ToDo check SalesForce integration
            company: formStore.contactData.company,
            businessType: businessTypeStore.chosenBusinessType,
            customer_number: formStore.contactData?.customerNumber,
            address: {
                street: formStore.contactData.address.street,
                house_number: formStore.contactData.address.houseNumber,
                zip_code: formStore.contactData.address.zipCode,
                place: formStore.contactData.address.place,
            },
            contactPerson: {
                first_name: formStore.contactData.contactPerson.firstname,
                last_name: formStore.contactData.contactPerson.lastname,
                email_invoice: formStore.contactData.contactPerson.email_invoice,
                phone: formStore.contactData.contactPerson.phone,
                fax: formStore.contactData.contactPerson?.fax,
                ik: formStore.contactData.contactPerson?.ik,
                club_member: formStore.contactData.contactPerson?.clubMember,
                ik_file: formStore.contactData.contactPerson?.ikFile,
                membership_file: formStore.contactData.contactPerson?.clubMemberFile,
            },
        },

        paymentMethod: {
            type: formStore.contactData.paymentMethod,
            account_holder: formStore.contactData.sepaData.accountHolder,
            address: formStore.contactData.sepaData.address,
            bic: formStore.contactData.sepaData.bic,
            iban: formStore.contactData.sepaData.iban,
            customer_number: formStore.contactData.cessionDeclaration.number
        },

        contract: {
            closingDate: new Date().toLocaleString(),
            valid_from: moment(formStore.conclusionData.contractDate).format("DD.MM.yyyy"),
            note: formStore.conclusionData.note,
            products: checkedProducts,
            signature: signature,
            price_model_type_id: formStore.priceModelData.price_model_type_id,
            price_model_id: formStore.priceModelData.id,

        },
        products: checkedProducts,
        summed_total_price_one_time: clientModelStore.summed_price_unique,
        summed_total_price_monthly: clientModelStore.summed_price_per_month_current
    }
    return await formStore.finishForm(clientModelObject);
}

export async function finishFormPackageModel(signature: string) {

    const formStore = useFormStore();
    const packageModelStore = usePackageModelProductStore();
    const businessTypeStore = useBusinessTypeStore();

    const checkedProducts: any[] = [];
    let paymentData = {};

    if (formStore.contactData.paymentMethod === 1) {
        paymentData = formStore.contactData.sepaData;
    } else {
        paymentData = formStore.contactData.sepaData;
    }

    packageModelStore.packageModelData.forEach((product) => {
        if (product?.checked === true) {
            checkedProducts.push(product);
        }
    });

    const packageModelObject = {
        salesForce:
            {
                quoteId: formStore.quoteId,
                salesId: formStore.salesId,
            },
        customer: {
            salesForceId: null, //ToDo check SalesForce integration
            company: formStore.contactData.company,
            businessType: businessTypeStore.chosenBusinessType,
            address: {
                street: formStore.contactData.address.street,
                house_number: formStore.contactData.address.houseNumber,
                zip_code: formStore.contactData.address.zipCode,
                place: formStore.contactData.address.place,
            },
            contactPerson: {
                first_name: formStore.contactData.contactPerson.firstname,
                last_name: formStore.contactData.contactPerson.lastname,
                email_invoice: formStore.contactData.contactPerson.email_invoice,
                phone: formStore.contactData.contactPerson.phone,
                fax: formStore.contactData.contactPerson?.fax,
                ik: formStore.contactData.contactPerson?.ik,
                club_member: formStore.contactData.contactPerson?.clubMember,
                ik_file: formStore.contactData.contactPerson?.ikFile,
                membership_file: formStore.contactData.contactPerson?.clubMemberFile
            },
        },

        paymentMethod: {
            type: formStore.contactData.paymentMethod,
            account_holder: formStore.contactData.sepaData.accountHolder,
            address: formStore.contactData.sepaData.address,
            bic: formStore.contactData.sepaData.bic,
            iban: formStore.contactData.sepaData.iban,
            customer_number: formStore.contactData.cessionDeclaration.number
        },

        contract: {
            closingDate: new Date().toLocaleString(),
            valid_from: moment(formStore.conclusionData.contractDate).toISOString(),
            note: formStore.conclusionData.note,
            products: checkedProducts,
            signature: signature,
            price_model_type_id: formStore.priceModelData.price_model_type_id,
            price_model_id: formStore.priceModelData.id,
        },
        products: checkedProducts,
        summed_total_price_one_time: packageModelStore.summed_price_unique,
        summed_total_price_monthly: packageModelStore.summed_price_per_month
    }

    console.log(packageModelObject.contract.valid_from);

    return await formStore.finishForm(packageModelObject);
}
