<template>

  <div :class="this.class" class="d-flex flex-column flex-grow-1">

    <v-card class="d-flex flex-column flex-grow-1">
      <v-card-title class="pa-4">
        <h2>{{ product.name }}</h2>
      </v-card-title>

      <v-card-text class="d-flex flex-column w-100 flex-grow-1">
        <div class="pa-2 card-text-container d-flex flex-grow-1">
          <div class="d-flex flex-column flex-grow-1">
            <div class="package-inner-header">
              <p><u>Module</u></p>
            </div>
            <div class="card-text-color" v-html="product.description_module"/>
            <div class="package-inner-header">
              <p><u>Lizenzen</u></p>
            </div>
            <div class="card-text-color" v-html="product.description_licence"/>
            <div class="package-inner-header">
              <p><u>Services</u></p>
            </div>
            <div class="card-text-color" v-html="product.description_service"/>
            <div class="package-inner-header">
              <p><u>Laufzeit</u></p>
            </div>
            <div class="card-text-color" v-html="product.description_runtime"/>
          </div>
        </div>


      </v-card-text>

      <v-card-actions>
        <ProductCostFooter :product="product"/>
      </v-card-actions>
    </v-card>
  </div>

</template>


<script>

import {useFormStore} from "@/stores/FormStore";
import {usePackageModelProductStore} from "@/stores/PackageModelProductsStore";
import {convertToGermanCurrency} from "@/services/Helper.service";
import ProductCostFooter from "@/components/packageModel/items/ProductCostFooter.vue";

export default {
  components: {ProductCostFooter},

  setup() {
    const formStore = useFormStore();
    const packageModelProductStore = usePackageModelProductStore();

    return {formStore, packageModelProductStore}
  },

  methods: {
    convertToGermanCurrency,
  },

  computed: {},
  props: ['product', 'class']
}


</script>


<style scoped>


.v-card {
}

.v-card-text {
  padding: 0;
}

.v-card-title {
  padding: 16px;
  background-color: var(--main-package-color-1);
  color: white;
  font-family: var(--main-font-family);
  text-align: center;
}

.v-card-actions {
  padding: 16px;
  background-color: var(--main-package-color-1);
  color: black;
  font-family: var(--main-font-family);
  text-align: center;
}

.package-inner-header {
  margin-top: 3px;
  background-color: var(--main-package-color-2);
  color: black;
  font-family: var(--main-font-family);
}

.card-actions-price {
  min-height: 40px;
}

.card-text-color {
  background-color: var(--main-package-color-3);
  padding: 6px;
}

.card-text-container {
  background-color: var(--main-package-color-2);
}

</style>