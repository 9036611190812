<template>

  <div class="d-flex flex-column">

    <div class="d-flex flex-row justify-center mt-6">
      <div class="d-flex flex-column align-center">
        <h2 class="single-form-page-header text-center">Vielen Dank für Ihre Bestellung</h2></div>
    </div>
  </div>


  <div class="d-flex flex-row justify-center ">

    <div class="d-flex flex-column  justify-center w-33 mt-10 finish-container">
      <p>
        Wir freuen uns, dass Sie sich für <span class="main-color-1">od </span> | <span class="main-color-2">care</span> entschieden haben.
      </p>
      <p>In kürze werden Sie die Bestelleingangsbestätigung auch in Ihrem angegebenen E-Mail-Postfach finden.</p>
      <p>Bitte beachten Sie, dass diese E-Mail auch in Ihren <strong>SPAM-Ordner</strong> zu finden sein kann.</p>
      <p>Wir bedanken uns für Ihr Vertrauen und freuen uns auf eine erfolgreiche Partnerschaft.</p>
      <p>Ihr Team der opta data IT Solutions GmbH!</p>

      <button @click="formStore.currentStep --"> zurück</button>

    </div>

  </div>

</template>


<script>

import {useFormStore} from "@/stores/FormStore";

export default {

  setup() {
    const formStore = useFormStore();

    return {formStore}
  }

}

</script>

<style>

.finish-container p {
  text-align: justify;
  margin-top: 25px;
  font-family: var(--main-font-family);
}

</style>