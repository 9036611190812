<template>

  <div class="d-flex flex-row w-100 pb-0 align-stretch justify-end">

    <div class="d-flex flex-column mr-4 text-center">
      <p class="item-header-text">einmalig</p>
      <div class="ufo-big main-color-1"><p style="padding-top: 10px; padding-bottom: 10px;">{{ singleCosts }}</p></div>
    </div>

    <div class="d-flex flex-column text-center">
      <p class="item-header-text flex-shrink-0">monatlich</p>
      <div v-if="priceDeviation" class="d-flex flex-column flex-grow-1 align-center ufo-big main-color-1">
        <p class="">Erste x Monate: {{ monthlyDeviationCosts() }}</p>
        <p class="">danach: {{ monthlyCosts() }}</p>
      </div>

      <div v-else class="d-flex flex-column flex-grow-1 justify-center ufo-big main-color-1">
        <p class="">{{ monthlyCosts() }}</p>
      </div>
    </div>
  </div>

</template>


<script>

import {useFormStore} from "@/stores/FormStore";
import {usePackageModelProductStore} from "@/stores/PackageModelProductsStore";
import {convertToGermanCurrency} from "@/services/Helper.service";

export default {

  setup() {
    const formStore = useFormStore();
    const packageModelProductStore = usePackageModelProductStore();
    return {formStore, packageModelProductStore}
  },

  methods: {

    monthlyDeviationCosts(){

      let sum = 0;

      this.packageModelProductStore.packageModelData.forEach(product => {

        if(product?.checked){
          if(product.price_deviation > 0){

            if(product?.orderedAmount){
              sum += product.price_deviation * product.orderedAmount;
            } else {
              sum += product.price_deviation;
            }

          } else {
            if(product?.orderedAmount){
              sum += product.price_per_month * product.orderedAmount;
            } else {
              sum += product.price_per_month;
            }
          }
        }
      });

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.packageModelProductStore.summed_deviation_per_month = sum.toFixed(2);
      return convertToGermanCurrency(this.packageModelProductStore.summed_deviation_per_month)

    },

    monthlyCosts() {

      let sum = 0;
      let priceDeviationCount = 0;

      this.packageModelProductStore.packageModelData.forEach(product => {
        if(product?.checked){

          if(product.price_deviation > 0){
            priceDeviationCount++;
          }

          if(product?.orderedAmount){
            sum += product.price_per_month * product.orderedAmount;
          } else {
            sum += product.price_per_month;
          }
        }
      });

      this.priceDeviation = priceDeviationCount > 0;

      this.packageModelProductStore.summed_price_per_month = sum.toFixed(2);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return convertToGermanCurrency(this.packageModelProductStore.summed_price_per_month)
    },

  },

  data() {
    return {
      priceDeviation: false,
    }
  },

  computed: {


    singleCosts() {
      let sum = 0;

      this.packageModelProductStore.packageModelData.forEach(product => {

        if(product?.checked){
          if(product?.orderedAmount){
            sum += product.price_unique * product.orderedAmount;
          } else {
            sum += product.price_unique;
          }
        }
      });

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.packageModelProductStore.summed_price_unique = sum.toFixed(2);
      return convertToGermanCurrency(this.packageModelProductStore.summed_price_unique)

    }
  }
}

</script>

<style scoped>

.item-header-text {
  margin-bottom: 8px;
}

.no-wrap{
  white-space: nowrap;
}

</style>
