<template>

  <div class="service-content" :style="{'background': backgroundColor}">

    <div v-if="product && product.position"
         :class="{'recommended-item': product?.suggested, 'ufo-small': product?.suggested}"
         class="w-100 h-100 d-flex flex-row justify-space-between align-center">
      <v-checkbox v-model="updateProduct" density="compact"
                  hide-details="auto"
                  :label="product.position"></v-checkbox>
      <h3>{{ convertToGermanCurrency(product.price_per_client) }}</h3>
    </div>

    <div class="recommended-text-container" v-if="product?.suggested">
      <img
          :src="require('@/assets/images/arrow-recommandation.svg')" alt="">
      <span class="recommend-text poppins medium main-color-1">Empfohlen</span>
    </div>

  </div>


</template>


<script>

import {useFormStore} from "@/stores/FormStore";
import {convertToGermanCurrency} from "@/services/Helper.service";
import {useClientModelProductStore} from "@/stores/ClientModelProductsStore";

export default {
  props: ['backgroundColor', 'product'],

  setup() {
    const formStore = useFormStore();
    const clientModelProductStore = useClientModelProductStore();

    return {formStore, clientModelProductStore}
  },

  methods: {
    convertToGermanCurrency,
  },

  computed: {
    updateProduct: {
      get() {
        return this.product?.checked;
      },
      set(newSelectedValue) {

        let myNewProduct = this.product;
        myNewProduct.checked = newSelectedValue;

        this.clientModelProductStore.updateClientModelProduct(myNewProduct);

      }
    },
  }
}

</script>

<style>

.recommended-text-container {
  position: absolute;
  bottom: -3px;
}

.service-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  padding: 16px;
  flex-basis: 25%;
}

.service-content .v-label {
  margin-left: 4px;
}

.recommend-text {
  margin: 8px 4px 4px;
}

.recommended-item {
  background: #2E6F82;
}

.recommended-item h3 {
  color: white;
}

.recommended-item .v-icon {
  color: white;
  opacity: unset;
}


.recommended-item .v-label {
  color: white;
}


</style>
