<template>
  <v-card class="content-container">
    <v-card-text class="content-text d-flex flex-column">


      <FinishFormLoadingDialog v-model:dialog-open="dialogFinish" v-model:finish-form-error="finishFormError"
                               v-model:finish-form-request-pending="finishFormRequestPending" @close-dialog="dialogFinish = false"/>

      <v-form class="p-4">
        <div class="pa-4">

          <h2 class="main-color-1 mb-4"> Zusammenfassung </h2>

          <div class="d-flex flex-row w-100 pa-4 pl-0">
            <div class="d-flex flex-column mr-6"><p class="step-3-item-header-text">Anzahl der Klienten:</p>

              <v-text-field :disabled="true" :model-value="formStore.numberOfClients" variant="outlined"
                            density="compact"
                            hide-details="auto"></v-text-field>
            </div>
            <div class="d-flex flex-column mr-auto"><p class="step-3-item-header-text">Anzahl der Klienten
              (intensiv):</p>

              <v-text-field :disabled="true" :model-value="formStore.numberOfIntenseClients" variant="outlined"
                            density="compact"
                            hide-details="auto"></v-text-field>
            </div>
          </div>

          <div v-if="formStore.getCheckedProductsByPageId(2).length > 0">
            <div class="d-flex flex-column w-100 mt-4">
              <div class="d-flex">

                <h2 class="main-color-1 mt-4 content-header-item-container"> Servicepaket </h2>
                <p class="d-flex main-color-1 content-header-item-50 justify-end">je Klient</p>
              </div>
            </div>

            <div v-for="product in formStore.getCheckedProductsByPageId(2)" :key="product">
              <ModuleContentItem :product="product"/>
            </div>
          </div>

          <div v-if="formStore.getCheckedProductsByPageId(3).length > 0">
            <div class="d-flex flex-column w-100 mt-4">
              <div class="d-flex">

                <h2 class="main-color-1 mt-4 content-header-item-container"> Module </h2>
                <p class="d-flex main-color-1 content-header-item-50 justify-end">je Klient</p>
              </div>
            </div>

            <div v-for="product in formStore.getCheckedProductsByPageId(3)" :key="product">
              <ModuleContentItem :product="product"/>
            </div>
          </div>

          <div v-if="formStore.getCheckedProductsByPageId(4).length > 0">
            <div class="d-flex flex-column w-100 mt-4">
              <div class="d-flex">

                <h2 class="main-color-1 mt-4 content-header-item-container"> Monatliche Zusatzleistungen </h2>
                <p class="d-flex main-color-1 content-header-item-33 justify-end">Anzahl</p>
                <p class="d-flex main-color-1 content-header-item-33 justify-end">Einzelpreis einmalig</p>
                <p class="d-flex main-color-1 content-header-item-33 justify-end">Gesamtpreis einmalig</p>

              </div>
            </div>
            <div v-for="product in formStore.getCheckedProductsByPageId(4)" :key="product">
              <AdvancedModuleContentItemELearning :product="product"/>
            </div>
          </div>


          <div v-if="formStore.getCheckedProductsByPageId(5).length > 0">
            <div class="d-flex flex-column w-100 mt-4">
              <div class="d-flex">

                <h2 class="main-color-1 mt-4 content-header-item-container"> E-Learning </h2>
                <p class="d-flex main-color-1 content-header-item-33 justify-end">Anzahl</p>
                <p class="d-flex main-color-1 content-header-item-33 justify-end">Einzelpreis einmalig</p>
                <p class="d-flex main-color-1 content-header-item-33 justify-end">Gesamtpreis einmalig</p>

              </div>
            </div>
            <div v-for="product in formStore.getCheckedProductsByPageId(5)" :key="product">
              <AdvancedModuleContentItemELearning :product="product"/>
            </div>
          </div>

          <v-divider thickness="2" style="color: #005d75" class="ma-4 ml-0 mr-0 align-self-center"></v-divider>

          <h2 class="main-color-1 mt-4"> Gesamtkosten </h2>

          <div class="d-flex justify-center align-center">

            <div class="d-flex flex-column mr-4 text-center">
              <p class="item-header-text mb-2">einmalig</p>
              <div class="ufo-big main-color-1"><p style="padding-top: 10px; padding-bottom: 10px;">
                {{ convertToGermanCurrency(clientModelProductStore.summed_price_unique) }}</p></div>
            </div>

            <div class="d-flex flex-column text-center">
              <p class="item-header-text">monatlich</p>
              <div class="ufo-big main-color-1">

                <div class="d-flex flex-column"></div>
                <p>min.: {{ convertToGermanCurrency(clientModelProductStore.summed_price_per_month) }}</p>
                <p>aktl.: {{ convertToGermanCurrency(clientModelProductStore.summed_price_per_month_current) }}</p>
              </div>
            </div>
          </div>

          <div class="mt-15 w-25 d-flex flex-column">

            <p class="b1 mb-2">Vertragsbeginn (Wunsch):</p>

            <VueDatePicker id="datepickerFrom"
                           v-model="formStore.conclusionData.contractDate"
                           format="dd.MM.yyyy"
                           select-text="Speichern"
                           cancel-text="Abbrechen"
                           :min-date="minDate"
                           :max-date="maxDate"/>
          </div>

          <v-textarea style="display: none" class="mt-5" v-model="formStore.conclusionData.note"
                      label="Anmerkungen"></v-textarea>


          <v-checkbox class="mt-15" v-model="formStore.conclusionData.agb" value="agb" density="compact"
                      hide-details="auto"
          >

            <template v-slot:label>
              <div>Ich stimme den <a target="_blank" href="" @click="formStore.openPriceModelPdfUrl('agb_file')">Allgemeinen Geschäftsbedingungen</a>, dem
                <a target="_blank" href="" @click="formStore.openPriceModelPdfUrl('order_processing_contract_file')">Auftragsverarbeitungsvertrag</a> sowie der
                Beendigung der o.g. bestehenden Verträge zu. Die <a target="_blank" href="" @click="formStore.openPriceModelPdfUrl('transparency_file')">Transparenzgebote</a> finden Sie hier.
              </div>
            </template>

          </v-checkbox>

          <v-checkbox v-model="formStore.conclusionData.dsgvo" value="dsgvo" density="compact"
                      hide-details="auto"
          >
            <template v-slot:label>
              <div>Ich bin damit einverstanden, dass meine oben angegebenen Kontaktdaten seitens der Firmen der “opta
                data Unternehmensgruppe“ (Auflistung unter:
                https://www.optadata-gruppe.de/unternehmen/unternehmen-der-gruppe) zum Zwecke der Information zum
                Produkt- und Leistungsspektrum der opta data Unternehmensgruppe, Markt- und Brancheninformationen, der
                Produktwerbung und der Kontaktaufnahme genutzt werden. Meine freiwillig getätigte Einwilligung kann ich
                jederzeit für die Zukunft gegenüber der opta data IT Solutions GmbH per E-Mail an xx@xxx.de insgesamt
                oder einzeln (bzgl. der Gruppenunternehmen bzw. der Kontaktart) widerrufen. Nach Erhalt des Widerrufs
                werden die betreffenden Daten nicht mehr zu den angeführten Zwecken genutzt.
              </div>
            </template>
          </v-checkbox>



          <div v-if="formStore.quoteId !== null && formStore.salesId !== null">
            <h2 class="main-color-1 mt-15"> Digitale Unterschrift </h2>

            <div class="mt-4 signature-container">

              <Vue3Signature ref="signature1" :sigOption="state.option" :w="'100%'" :h="'250px'"
                             :disabled="state.disabled" class="example"></Vue3Signature>

            </div>
            <button class="button-standard-continue mt-3" @click="clear">Unterschrift neu</button>
          </div>
        </div>
      </v-form>

    </v-card-text>

    <v-card-actions class="d-flex flex-column">

      <MaskDescription/>

      <div class="actions-container">

        <div class="d-flex flex-column align-center">
          <button @click="finishForm()" class="button-standard-continue d-flex align-center justify-center">
            <span class="poppins medium">Jetzt kostenpflichtig bestellen</span>
            <img class="ml-2"
                 :src="require('@/assets/images/payment.svg')" alt="">
          </button>

          <button @click="formStore.currentStep--"
                  class="mt-8 w-50 button-standard-back d-flex align-center justify-center">
            <span>Abbrechen</span>
          </button>

        </div>
      </div>

    </v-card-actions>

  </v-card>

</template>

<script>

import {reactive, ref} from "vue";
import '@vuepic/vue-datepicker/dist/main.css'
import {useFormStore} from "@/stores/FormStore";
import ModuleContentItem from "@/components/clientModel/items/FormStep7/ModuleContentItem.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import moment from "moment-timezone";
// eslint-disable-next-line no-unused-vars
import {finishFormClientModel, finishFormPackageModel} from "@/services/FinishForm.service";
// eslint-disable-next-line no-unused-vars
import {CLIENT_MODEL} from "@/utils/constants";
import {convertToGermanCurrency} from "@/services/Helper.service";
import MaskDescription from "@/components/packageModel/items/MaskDescription.vue";
import AdvancedModuleContentItem
  from "@/components/clientModel/items/FormStep7/AdvancedModuleContentItem.vue";
import {useMaskStore} from "@/stores/MaskStore";
import {useClientModelProductStore} from "@/stores/ClientModelProductsStore";
import FinishFormLoadingDialog from "@/components/FinishFormLoadingDialog.vue";

export default {
  components: {
    FinishFormLoadingDialog,
    AdvancedModuleContentItemELearning: AdvancedModuleContentItem,
    MaskDescription,
    ModuleContentItem,
    VueDatePicker,
  },

  setup() {

    const formStore = useFormStore();
    const clientModelProductStore = useClientModelProductStore();
    const maskStore = useMaskStore();

    const today = moment();
    const daysLeft = today.clone().endOf('month').diff(today, 'days');
    const monthsToAdd = daysLeft < 7 ? 2 : 1;
    const minDate = ref(today.add(monthsToAdd, 'months').startOf('month').toDate());
    const maxDate = ref(moment().add(1, 'years').toDate());

    const state = reactive({
      count: 0,
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)"
      },
      disabled: false
    })

    const signature1 = ref(null)

    const save = (t) => {
      return signature1.value.save(t);
    }

    const clear = () => {
      signature1.value.clear()
    }

    return {state, signature1, save, clear, formStore, clientModelProductStore, minDate, maxDate, maskStore};

  },

  methods: {
    convertToGermanCurrency,

    getAGBLocation() {
      return this.getWindowLocation() + 'pdf/Anlage-A_AGB_Pflege.pdf';
    },

    getDSGVOLocation() {
      return this.getWindowLocation() + 'pdf/Anlage-D_Transparenzgebote_odITS.pdf';
    },

    getWindowLocation() {
      return window.location.href.split('?')[0];
    },

    async finishForm() {

      let signatureString = null;

      if(this.formStore.salesId !== null && this.formStore.quoteId !== null && !this.signature1.isEmpty()){
        signatureString = this.save();
      }

      this.finishFormRequestPending = true;
      this.dialogFinish = true;

      let data = null;

      try {
        if (this.formStore.priceModelData.price_model_type_id === CLIENT_MODEL) {
          data = await finishFormClientModel(signatureString);
        } else {
          data = await finishFormPackageModel(signatureString);
        }

        if (data !== null) {

          if (data.status === 200) {
            this.formStore.currentStep++;
          } else {
            this.finishFormError = true;
          }
        }

      } catch (e) {
        this.finishFormError = true;
      } finally {
        this.finishFormRequestPending = false;
      }
    },
  },

  data() {
    return {
      dialogFinish: false,
      finishFormError: false,
      finishFormRequestPending: false,

    }
  },
  mounted() {
    this.formStore.conclusionData.contractDate = this.minDate;
  },
}

</script>


<style scoped>
.example {
  margin: 0 auto;
}

.signature-container {
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
}

.content-header-item-33 {
  flex-basis: 33.33%;
  text-align: end;
}

.content-header-item-50 {
  flex-basis: 50%;
  text-align: end;
}

.content-header-item-container {
  flex-shrink: 0;
  flex-basis: 50%;
}

</style>
